import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ArrowUp } from "lucide-react";

const ScrollToButton = ({ to = "top" }) => {
	const [isVisible, setIsVisible] = useState(false);

	// Animation constants matching your design system
	const bounceTransition = {
		duration: 0.4,
		ease: [0.68, -0.25, 0.265, 1.25],
	};

	useEffect(() => {
		const handleScroll = () => {
			setIsVisible(window.scrollY > 400);
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const handleClick = () => {
		window.scrollTo({
			top: to === "bottom" ? document.documentElement.scrollHeight : 0,
			behavior: "smooth",
		});
	};

	return (
		<AnimatePresence>
			{isVisible && (
				<motion.div
					className="hidden md:fixed z-50 bottom-6 right-6"
					initial={{ opacity: 0, scale: 0.5 }}
					animate={{ opacity: 1, scale: 1 }}
					exit={{ opacity: 0, scale: 0.5 }}
					transition={bounceTransition}
				>
					<div className="group relative">
						{/* Tooltip */}
						<motion.div
							className="absolute bottom-full mb-2 left-1/2 -translate-x-1/2 bg-gray-900 text-white text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none whitespace-nowrap"
							initial={{ y: 5 }}
							animate={{ y: 0 }}
						>
							{`Scroll to ${to.toUpperCase()}`}
							<div className="absolute -bottom-1 left-1/2 -translate-x-1/2 border-4 border-transparent border-t-gray-900" />
						</motion.div>

						{/* Button */}
						<motion.button
							onClick={handleClick}
							className="flex items-center justify-center w-12 h-12 bg-primary hover:bg-secondary rounded-full shadow-lg transition-colors duration-200"
							whileHover={{ scale: 1.1 }}
							whileTap={{ scale: 0.95 }}
							transition={bounceTransition}
						>
							<ArrowUp
								className="w-5 h-5 text-white"
								style={{
									transform: to === "bottom" ? "rotate(180deg)" : "none",
								}}
							/>
						</motion.button>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default ScrollToButton;
