export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const SWATCH_CART_ADD_ITEM = "SWATCH_CART_ADD_ITEM";
export const SWATCH_CART_REMOVE_ITEM = "SWATCH_CART_REMOVE_ITEM";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
export const CART_SAVE_SHIPPING_ADDRESS = "CART_SAVE_SHIPPING_ADDRESS";
export const CART_SAVE_PAYMENT_RESULT = "CART_SAVE_PAYMENT_RESULT";
export const CART_UPDATE_QTY = "CART_UPDATE_QTY";
export const CART_SAVE_SUMMARY = "CART_SAVE_SUMMARY";
export const CART_RESET = "CART_RESET";
export const PROMO_CODE_SUBMITTED_REQUEST = "PROMO_CODE_SUBMITTED_REQUEST";
export const PROMO_CODE_SUBMITTED_SUCCESS = "PROMO_CODE_SUBMITTED_SUCCESS";
export const PROMO_CODE_SUBMITTED_FAIL = "PROMO_CODE_SUBMITTED_FAIL";
export const SWATCH_CART_SAVE_SHIPPING_ADDRESS =
  "SWATCH_CART_SAVE_SHIPPING_ADDRESS";
export const SWATCH_CART_SAVE_SUMMARY = "SWATCH_CART_SAVE_SUMMARY";
export const SWATCH_CART_RESET = "SWATCH_CART_RESET";
