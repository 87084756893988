import { CUSTOMIZE_PRODUCT } from "../constants/orderConstants";

const initialState = {
	customizeProduct: {
		productInfo: {},
		optionInfo: [],
	},
};
//Customize Product
export const customizeReducer = (state = initialState, action) => {
	switch (action.type) {
		case CUSTOMIZE_PRODUCT:
			return {
				customizeProduct: {
					...action.payload,
				},
			};

		default:
			return state;
	}
};
