import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import { encryptTransform } from "redux-persist-transform-encrypt";
import { customizeReducer } from "./reducers/customizeReducers";
import { cartReducer, swatchCartReducer } from "./reducers/cartReducers";
import {
	userDetailsReducer,
	userLoginReducers,
	userRegisterReducer,
} from "./reducers/userReducers";
import {
	getRatesReducer,
	orderCreateReducer,
	orderDetailsReducer,
	orderPayReducer,
} from "./reducers/orderReducers";
import { filterReducer } from "./reducers/filterReducer";
import { countryReducer } from "./reducers/countryReducer";

// Combine all reducers
const reducer = combineReducers({
	country: countryReducer,
	swatchCart: swatchCartReducer,
	cart: cartReducer,
	userLogin: userLoginReducers,
	userRegister: userRegisterReducer,
	userDetails: userDetailsReducer,
	orderCreate: orderCreateReducer,
	orderDetails: orderDetailsReducer,
	orderPay: orderPayReducer,
	rates: getRatesReducer,
	filter: filterReducer,
	customize: customizeReducer,
});

// Encrypt sensitive data in the Redux store
const persistConfig = {
	key: "root",
	storage: storage,
	transforms: [
		encryptTransform({
			secretKey: "my-super-secret-key",
			onError: function (error) {
				// Handle the error.
			},
		}),
	],
};

// Define initial state
const initialState = {
	country: "Canada",
	swatchCart: {
		swatchCartItems: [],
		swatchShippingAddress: {},
	},
	cart: {
		cartItems: [],
		shippingAddress: {},
		cartSummary: {},
	},
	customize: {
		customizeProduct: {},
	},
	userLogin: {
		userInfo: null,
	},
	rates: {
		deliveryType: {},
	},
	filter: {
		value: {
			color: [],
			light: [],
			price: [],
			type: [],
			operation: [],
			collection: [],
		},
	},
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, reducer);

// Apply middleware
const middleware = [thunk];

// Create the Redux store
const store = createStore(
	persistedReducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middleware))
);

// Create the persistor for persisting the Redux store
const persistor = persistStore(store);

// Export the store and persistor
export { store, persistor };
