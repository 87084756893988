import { COUNTRY_UPDATE } from "../constants/countryConstants";

export const countryReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_UPDATE:
      return action.payload;
    default:
      return state;
  }
};
