import React from "react";

const NotFound = () => {
	return (
		<div style={{ textAlign: "center", margin: "50px" }}>
			<h1 style={{ fontSize: "48px", color: "#ff0000" }}>
				404 - Page Not Found
			</h1>
			<p style={{ fontSize: "24px", color: "#333333" }}>
				The page you are looking for does not exist.
			</p>
		</div>
	);
};

export default NotFound;
