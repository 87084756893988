import React, { lazy, Suspense } from "react";
import {
	BrowserRouter as Router,
	Route,
	Navigate,
	Routes,
	useParams,
} from "react-router-dom";

// Importing CSS files for lazy loading support (no broken styles) -Omar
import "./App.css";
import { AnimatePresence } from "framer-motion";

// Changed pages to lazy loaded pages instead of importing all pages at once -Omar
import ScrollToButton from "./components/ScrollToButton";
import NotFound from "./components/NotFound";
const Customize_Details = lazy(() => import("./screens/customize/details"));
const Customize_Fabric = lazy(() => import("./screens/customize/fabric"));
const Customize_BottomBar = lazy(() => import("./screens/customize/bottombar"));
const Customize_Control = lazy(() => import("./screens/customize/control"));
const Customize_Valance = lazy(() => import("./screens/customize/valance"));
const Customize_Summary = lazy(() => import("./screens/customize/summary"));
const Footer = lazy(() => import("./components/footer"));
const Header = lazy(() => import("./components/header"));
const CartScreen = lazy(() => import("./screens/CartScreen"));

const InstallationScreen = lazy(() =>
	import("./screens/guides/InstallationScreen")
);
const MeasuringGuideScreen = lazy(() =>
	import("./screens/guides/MeasuringGuideScreen")
);
const NewHome = lazy(() => import("./screens/NewHome"));
const LandingPage = lazy(() => import("./screens/LandingPage"));
const ProductSelectionGuideScreen = lazy(() =>
	import("./screens/guides/ProductSelectionGuideScreen")
);
const ShippingPolicyScreen = lazy(() =>
	import("./screens/guides/ShippingPolicyScreen")
);
const ReturnPolicyScreen = lazy(() =>
	import("./screens/guides/ReturnPolicyScreen")
);
const PrivacyPolicy = lazy(() => import("./screens/guides/PrivacyPolicy"));
const TermsAndConditions = lazy(() =>
	import("./screens/guides/TermsAndConditions")
);
const WarrantyScreen = lazy(() => import("./screens/guides/WarrantyScreen"));
const LoginScreen = lazy(() => import("./screens/LoginScreen"));
const RecoveryScreen = lazy(() => import("./screens/RecoveryScreen"));
const PasswordResetScreen = lazy(() => import("./screens/PasswordResetScreen"));
const RegisterScreen = lazy(() => import("./screens/RegisterScreen"));
const ProfileScreen = lazy(() => import("./screens/ProfileScreen"));
const PlaceOrderScreen = lazy(() => import("./screens/PlaceOrderScreen"));
const ConfirmationScreen = lazy(() => import("./screens/ConfirmationScreen"));
const MyOrderScreen = lazy(() => import("./screens/MyOrderScreen"));
const ReferScreen = lazy(() => import("./screens/ReferFriend"));

const Contact = lazy(() => import("./screens/contact"));
const Guides = lazy(() => import("./screens/guides"));
const Showroom = lazy(() => import("./screens/showroom"));
const CustomizeShade = lazy(() => import("./screens/shop"));
const CustomizeShadeInfo = lazy(() => import("./screens/shop/info"));
const FabricSwatch = lazy(() => import("./screens/FabricSwatch"));
const Magazine = lazy(() => import("./screens/Magazine"));
const SwatchCheckout = lazy(() => import("./screens/SwatchCheckout"));
const Blog = lazy(() => import("./screens/blog"));
const BlogContent = lazy(() => import("./screens//blog/content"));
const AboutUs = lazy(() => import("./screens/about"));
const MotorizedSmart = lazy(() => import("./screens/shop/motorized-solutions"));
const FAQ = lazy(() => import("./screens/FAQScreen"));
const AutoScrollTop = lazy(() => import("./components/AutoScrollTop"));

function ExploreToShopRedirect() {
	const { id } = useParams();
	return <Navigate replace to={`/shop/info/${id}`} />;
}

const App = () => {
	document.body.style = "background: white;";

	return (
		// Suspense element used for lazy loading
		<Suspense>
			<Router>
				<AnimatePresence mode="popLayout">
					<Header />
					<AutoScrollTop />
					<Routes>
						<Route path="/special-offer" exact element={<LandingPage />} />
						<Route
							path="/customize/details"
							exact
							element={<Customize_Details />}
						/>
						<Route
							path="/customize/fabric"
							exact
							element={<Customize_Fabric />}
						/>
						<Route
							path="/customize/bottombar"
							exact
							element={<Customize_BottomBar />}
						/>
						<Route
							path="/customize/control"
							exact
							element={<Customize_Control />}
						/>
						<Route
							path="/customize/valance"
							exact
							element={<Customize_Valance />}
						/>
						<Route
							path="/customize/summary"
							exact
							element={<Customize_Summary />}
						/>
						<Route path="/about" element={<AboutUs />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/cart" element={<CartScreen />} />
						{/* <Route path="/guides" exact element={<Guides />} /> */}
						<Route
							path="/guides/productselection"
							exact
							element={<ProductSelectionGuideScreen />}
						/>
						<Route
							path="/guides/measuring-guide"
							exact
							element={<MeasuringGuideScreen />}
						/>
						<Route
							path="/guides/installation-guide"
							exact
							element={<InstallationScreen />}
						/>

						<Route
							path="/help/privacy-policy"
							exact
							element={<PrivacyPolicy />}
						/>
						<Route
							path="/help/shipping-policy"
							exact
							element={<ShippingPolicyScreen />}
						/>
						<Route
							path="/help/return-policy"
							exact
							element={<ReturnPolicyScreen />}
						/>
						<Route
							path="/help/terms-conditions"
							exact
							element={<TermsAndConditions />}
						/>
						<Route path="/help/faq" exact element={<FAQ />} />
						<Route path="/help/warranty" exact element={<WarrantyScreen />} />
						<Route path="/login" exact element={<LoginScreen />} />
						<Route
							path="/account-recovery"
							exact
							element={<RecoveryScreen />}
						/>
						<Route
							path="/reset-password"
							exact
							element={<PasswordResetScreen />}
						/>
						<Route path="/register" exact element={<RegisterScreen />} />
						<Route path="/profile" exact element={<ProfileScreen />} />
						<Route path="/myorders" exact element={<MyOrderScreen />} />
						<Route path="/placeorder" exact element={<PlaceOrderScreen />} />
						<Route
							path="/confirmation"
							exact
							element={<ConfirmationScreen />}
						/>
						<Route path="/refer-a-friend" exact element={<ReferScreen />} />

						<Route path="/" exact element={<NewHome />} />
						<Route path="/shop" exact element={<CustomizeShade />} />
						<Route path="/explore" element={<Navigate replace to="/shop" />} />
						<Route
							path="/shop/info/:id"
							exact
							element={<CustomizeShadeInfo />}
						/>
						<Route
							path="/explore/info/:id"
							element={<ExploreToShopRedirect />}
						/>

						<Route
							path="/motorized-solutions"
							exact
							element={<MotorizedSmart />}
						/>

						<Route path="/swatches" exact element={<FabricSwatch />} />
						<Route path="/magazine" exact element={<Magazine />} />
						<Route path="/showroom" exact element={<Showroom />} />
						<Route path="/swatchcheckout" exact element={<SwatchCheckout />} />

						<Route path="/blog" exact element={<Blog />} />

						<Route path="/blog/:id" exact element={<BlogContent />} />
						<Route path="/404" element={<NotFound />} />
						<Route path="*" element={<Navigate replace to="/404" />} />
					</Routes>
				</AnimatePresence>
				<Footer />
				<ScrollToButton />
			</Router>
		</Suspense>
	);
};

export default App;
