import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react"; // Import PersistGate
import App from "./App";
import { store, persistor } from "./store"; // Import store and persistor
import { PostHogProvider } from "posthog-js/react";

const options = {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

// Use PersistGate to wrap your App component
createRoot(document.getElementById("root")).render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<PostHogProvider
				apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
				options={options}
			>
				<App />
			</PostHogProvider>
		</PersistGate>
	</Provider>
);
